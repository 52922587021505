/**
 *  NAVBAR
 */

.navbar_container {
  a{
    &:hover, &:active{
      color: $font_color_white
    }
  }

  .md-button.navbar_highlight_button {
    border: 2px solid $yellow;
    color: $yellow;
    border-radius: 50px;
    //padding: 9px 11px;
    //width: 108px;
    padding-top: .01rem;
    padding-bottom: .01rem;
    transition: color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover, &:active {
      background-color:$yellow !important;
      color: $off_black !important;
    }
  }

  .logo_link {
    text-align: center;

    .logo_icon {
      height: 32px;
      width: 32px;
    }
  }

  .nav_icon {
    margin-right: 4px;
    height: 14px !important;
    width: 14px !important;
    svg {
      fill: $yellow;
    }
  }

  .navbar_signup_button {
    .md-button {
      padding: 2px 0px;
      border: 2px solid $yellow;
      color: $yellow;
      border-radius: 50px;
      width: 79px;
    }
    .md-button:hover {
      background-color: $yellow;
      color: $off_black;
    }
  }

}

.navbar_avatar_icon {
  width: 38px;
  height: 38px;
  border-radius: 19px;
}

// Make sure the Dropdown stays below the button pushed
.md-open-menu-container.md-active{
  margin-top: 50px !important;
}

