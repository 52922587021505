// /**
//  *  SEARCH COMPONENT
//  */


#search input {
    background: url(../assets/images/search_icon_black.svg);
    background-repeat: no-repeat;
    background-position: 10px 12px;
    background-size: 18px;
    padding: 0px 35px;
}

.search_results {
  display: flex;
  align-items: center;
  .result_icon {
    flex: 0 0 18px;
    margin-right: 12px;
    height: 18px;
  }
  .result_name {
    margin-right: 10px;
  }
  .result_location {
    color: $blue;
  }
}
