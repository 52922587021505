section .stickMenu {
  position: absolute !important;
//  top: 64px;
}

.stickNav {
  position: fixed;
  top: 0px;
  border-bottom:1px solid #666;
}
