/*
  MODAL STORE
*/

.store_itemDescription {
  margin-left:20px;
  ul {
    list-style-image: url('../assets/images/check_circle.svg');
    list-style-position: inherit;
    font-size: .8em;
    line-height: 1.8em;
  }
}
