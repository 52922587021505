// General helpers
.border-black{
  border: 3px solid $off_black;
}
.border-white{
  border: 3px solid white;
}
.color-white{
  color: white !important;
}
.color-dropdown{
  color: $grey_darkest !important;
}
.color-black{
  color: $off_black;
}
.color-blue{
  color:$terciary_color;
}
.color-cyan{
  color: $terciary_color;
}
.color-green {
  color: $green;
  svg {
    fill: $green;
  }
}
.color-red {
  color: $red;
  svg {
    fill: $red !important;
  }
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}
.d-flex{
  display:flex;
}
.full-width{
  width: 100%;
}
.max-width-500px {
  max-width: 500px;
}
.min-height-80 {
  min-height: 80%;
}

// Font & text
.font-roboto-condensed{
  font-family:'Roboto Condensed';
}
.font-kanit{
  font-family:'Kanit';
}

.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}



// Background helpers
.bg-white{
  background-color: #fff;
}
.bg-black{
  background-color: black;
}
.bg-green{
  background-color: $green;
}
.bg-blue{
  background-color: $blue;
}
.bg-red{
  background-color: $red !important;
}
.bg-red-light{
  background-color: rgba($red,.2);
}
.bg-yellow{
  background-color: $yellow;
}
.bg-grey {
  background-color: $grey !important;
}
.bg-grey-lightest {
  background-color: $grey_lightest !important;
}

.outline{
  outline: 1px solid red;
}

// Button helpers
.btn-outline-blue{
  border:2px solid $blue;
  color: $blue;
  padding:10px 29px;
  svg {
    fill: $blue
  }
}
.btn-outline-blue:hover{
  background: $blue !important;
  color: white;
  svg {
    fill: white
  }
}
.btn-outline-white{
  border:2px solid white;
  color: white;
  padding:10px 29px;
}
.btn-outline-white:hover{
  background: white !important;
  color: $off_black;
}
.btn-outline-white-blue{
  border:2px solid white;
  color: white;
  padding:10px 29px;
}
.btn-outline-white-blue:hover{
  background: $blue !important;
}
.btn-outline-yellow{
  border:2px solid $yellow;
  color: $yellow;
  padding:10px 29px;
}
.btn-outline-yellow:hover{
  background: $yellow !important;
  color: $off_black;
}
.btn-yellow{
  border:2px solid $yellow;
  background: $yellow;
  color: $off_black;
  padding:12px 29px;
}

.btn-yellow:hover{
  background: $off_black !important;
  color: $yellow;
}
.btn-black-outline{
  border:2px solid $off_black;
  color: $off_black;
  padding:10px 29px;
}
.btn-black-outline:hover{
  background-color: $off_black !important;
  color: white;
}
.btn-blue{
  border:2px solid $blue;
  background: $blue;
  color: white;
  padding:12px 29px;
}

.btn-blue:hover{
  color: $blue;
  background: $grey_lighter !important;
}
.btn-white-blue{
  border:2px solid white;
  background: none;
  color: white;
  padding:12px 29px;
}

.btn-white-blue:hover{
  color: $blue;
  background: white !important;
  border:2px solid $blue;
}

// TODO: Cosmetic Fix buttons in general
.btn-round-new {
  border-radius: 40px;
  font-family: 'Roboto Condensed';
  font-size: 16px;
  // height: 44px;
  line-height: 40px;
  font-weight: $font-weight-heavy;
  letter-spacing: 0.0325em;
  padding:0px 35px;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn-round{
  border: 0;
  border-radius: 25px;
  padding:5px 35px;
  font-size:18px;
}

.btn-cyan{
  background-color: $terciary_color;
  color:white;
  border:2px solid $terciary_color;
}

.btn-cyan:hover{
  color: $terciary_color;
  background: white !important;
  border:2px solid $terciary_color;
}

// display helpers
.btn-full {
  display: block;
  width: 100%;
  border-radius: 0px;
  margin: 0px;
  padding:12px 29px;
}

.btn-small {
  min-width: unset !important;
  min-height: unset !important;
  //outline: 1px solid red;
}

// Margin - padding helpers
.m-0{
  margin: 0;
}
.m-10{
  margin: 10px;
}
.ml-0 {
  margin-left: 0px;
}
.ml-15 {
  margin-left: 15px;
}
.mt-10{ margin-top: 10px; }
.mt-20{ margin-top: 20px; }
.mt-30{ margin-top: 30px; }
.mt-50{ margin-top: 50px; }
.mr-10{ margin-right: 10px; }
.mr-20{ margin-right: 20px; }
.mr-30{ margin-right: 30px; }
.mr-50{ margin-right: 50px; }
.mb-10{ margin-bottom: 10px; }
.mb-20{ margin-bottom: 20px; }
.mb-30{ margin-bottom: 30px; }
.mb-50{ margin-bottom: 50px; }
.ml-10 { margin-left: 10px; }
.ml-20 { margin-left: 20px; }
.ml-30 { margin-left: 30px; }
.ml-50{ margin-left: 50px; }
.my-20{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mw-100{
  max-width: 100%;
}
.p-0{ padding: 0; }
.p-10{ padding: 10px; }
.p-20{ padding: 20px; }
.p-30{ padding: 30px; }
.p-40{ padding: 40px; }
.p-50{ padding: 50px; }
.p-60{ padding: 60px; }
.pl-20{ padding-left: 20px; }
.pt-80{ padding-top:80px; }
.py-40{
  padding-top:40px;
  padding-bottom:40px;
}
.px-20{
  padding-left:20px;
  padding-right:20px;
}
.px-40{
  padding-left:40px;
  padding-right:40px;
}


// img helpers

.img-circle {
  border-radius: 50%;
}

footer{
  p{
    font-size:12px !important;
    line-height:12px !important;
  }
}

// postion helpers

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

// size helpers
.s-48 {
  width: 48px;
  height: 48px;
}
