@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,300italic,400italic,700,700italic|Roboto:400,700,700italic,400italic);
@import url('https://fonts.googleapis.com/css?family=Kanit:400,500,600,700,900');

@import '_variables';
@import 'helpers';
@import 'components/landing/landing';
@import 'components/layout/layout';
@import 'components/search/search';
@import 'components/kit/kit';
@import 'components/kit/showKit/showKit';
@import 'components/store/storeModal';
@import 'components/upload/csvUpload';
@import 'core/animation/backdrop/loadingBackdrop';
@import 'core/animation/animation.directive';

/**
 *  MOZILLA-SPECIFIC CSS
 */
@-moz-document url-prefix() {
  .kit_detailed .kit_chart .container .kit_chart_left .sensor_select .sensor_icon_selected {
    transform: translateY(-8px);
  }
  .kit_detailed .kit_chart .container .kit_chart_left .sensor_compare md-select {
    transform: translateY(8px);
    margin: 0;
  }
  body .kit_detailed .kit_chart .container .kit_chart_left .sensor_data {
    margin: 5% 0 4% 0;
  }

/*   section.map {
    z-index: 0;
  } */
}

/**
 *  INPUT CSS
 */
//
// ::-webkit-input-placeholder { /* WebKit browsers */
//   color: $font_color_white;
// }
// :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
//   color: $font_color_white;
//   opacity:  1;
// }
// ::-moz-placeholder { /* Mozilla Firefox 19+ */
//   color:  $font_color_white;
//   opacity:  1;
// }
// :-ms-input-placeholder { /* Internet Explorer 10+ */
//   color: $font_color_white;
// }
//
// :focus::-webkit-input-placeholder { /* WebKit browsers */
//   color: $background_blue_dark_background;
// }
// :focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
//   color: $background_blue_dark_background;
//   opacity:  1;
// }
// :focus::-moz-placeholder { /* Mozilla Firefox 19+ */
//   color: $background_blue_dark_background;
//   opacity:  1;
// }
// :focus:-ms-input-placeholder { /* Internet Explorer 10+ */
//   color: $background_blue_dark_background;
// }

html {
  font-family: 'Roboto Condensed';
  font-size: 18px;
}

body {
  background-color: rgba(0, 0, 0, 0);
  color: $off_black;
}

a.about{
  cursor: pointer;
  text-decoration: none;
  color: $blue;
}

li.policy-toc {
  padding: 3px;
  a {
    text-decoration: none;
  }
}

ul#policy-toc {
  padding-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.policy-toc#header{
  list-style: none;
}

.icon_label{
  width: 14px;
  height: 14px;
  margin-right: 4px;
  position: relative;
  top: -2px;
  svg {
    fill: $grey_darker;
  }
}

/**
  * TYPOGRAPHY
  */

h1{
  font-size: 2.2em;
  font-weight: normal;
}
h2 {
  font-size: 1.7em;
  font-weight: normal;
}
h3 {
  font-size: 1.4em;
  font-weight: normal;
}
h4 {
  font-size: 1.2em;
  font-weight: normal;
}


p {
  line-height: 1.5;
}

td {
  padding:5px;
}

input{
  font-family:'Roboto Condensed';
}

.label, .tag, .state{
  text-transform: uppercase;
  font-size: 12px;
  line-height:24px;
  border-radius:20px;
  padding: 3px 8px;
  margin-right: 8px;

  md-icon{
    margin-left:4px;
    height:11px;
    width:11px;
    margin-top:-3px;
    cursor:pointer;
  }
  &[clickable]{
    cursor:pointer;
  }
}
.label{
  background-color: $yellow_light;
  color: $off_black;
  md-icon svg path{
    fill: $terciary_color_light;
  }
}
.tag {
  background-color: $yellow_lighter;
  color: $off_black;
  md-icon svg path{
    fill: $secondary_color;
  }
}

.leaflet-container .tag, .leaflet-container .label,
.leaflet-container .state{
  font-size:1em;
}

.leaflet-top{
   top:93px;
}

section.info{
  h1{
    font-size:1.3em;
  }
}

a{
  color: $off_black;
  &:hover, &:active{
    text-decoration: none;
    color: $blue;
  }
}

a.footer-link{
  color: $yellow;
  text-decoration: none;
  transition: color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover, &:active{
    text-decoration: none;
    color:$blue;
  }
}
// a.md-button, a,
// button.md-button, button{
//   // color here overwrites MAP and COMMUNITY in navbar
//   // color: $terciary_color;
// }

/*
  Overwrite global material design, allow icons to be smaller
*/

md-toolbar{
  background-color: $background_blue_dark_navbar !important;
  z-index: $large_priority;
  color: white !important;
  md-icon{
    fill: white !important;
  }
  //height: 64px;
}

md-icon{
  // Effects ALL icons on the site
  min-width: unset !important;
}

md-select{
  md-select-value{
    border-bottom-color: #cccccc75 !important;
  }
}

a.md-button h4, a.md-button .h4{
  color: $terciary_color;
  margin: 0;
}
a.warn,
button.warn{
  color: $alert_red;
  // svg {
  //   // fill: $alert_red;
  // }
}

.bold{
  font-weight: bold;
}

div[api-key]{
  display: flex;

  .api_key_number {
    background-color: #F2F2F2;
    word-break: break-all;
    width: 420px;
  }
  .api_key_refresh_button {
    background-color: #DBDBDB;
    margin:0;
    md-icon {
      padding: 10px;
      height: 15px;
      width: 15px;
    }
  }
  md-icon {
    opacity: .5;
  }
}

.info_overlay{
  h2{
    color:white;
  }
  p{
    margin: 0;
    font-size: 20px;
    font-weight: 300;
    color:$terciary_color_light;
  }
}

.dark{
  h1, h2{
    color:white;
  }
  h1, h2{
    margin:0;
  }
  h3, h4, .h4, a{
    color: $font_color_blue_grey;
  }
}

/**
 * ANGULAR MATERIAL OVERRIDING
 */


md-select-menu {
  background-color: white;
}
md-select,
md-select-menu {
  text-transform: uppercase;
}
md-select:not([disabled]):focus .md-select-value {
  border-bottom-color: #6B868D;
}

// button.md-primary{
//   // Get new kit popup, button color
//   // Also interferes with kits/edit save button
//   // color:$terciary_color !important;
//   // font-size:18px;
// }
// .md-button.md-flat.md-primary {
//   //margin: 0px;
//   //background-color: $terciary_color;
//   //color: white !important;
// }

.md-button.md-primary.md-flat:not([disabled]):hover {
  background-color: $terciary_color;
  color: white;
}

.md-button.md-primary.md-flat:not([disabled]):focus {
  background-color: $terciary_color;
  color: white;
}

.md-button.fillwidth {
  width: 100% !important;
}

md-input-container:not(.md-input-invalid).md-input-focused {
  label {
    color: $terciary_color;
  }

  .md-input {
    border-color: rgba(0,0,0,0.12);
  }
}

/**
 * ALERT COMPONENT
 */

.md-toast-content {
  box-shadow: unset !important;
  background-color: unset !important;
  color: black !important;
  min-width: 100%;
}

md-toast {
  // TODO: Bug when a toast is on screen, the body gets class='md-toast-open-bottom'
  // Where does that come from?
  // If there is a similar *open-top, we can remove the CSS bottom, top
  bottom: unset !important; // Overwrite angular default
  color: black;
  opacity: 0.95;
  padding: 0;
  position: fixed;
  top: 64px !important; // Start the toast at top below navbar.
  min-width: 100%;
  z-index: 15;

  .alert_typeIcon {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  .alert_closeIcon {
    width: 10.5px;
    height: 10.5px;
    margin: 0 auto;
  }

  .alert_button {
    padding: 0px 12px;
    margin-left: 5px;
    color: black !important;
    background-color: $yellow;
    border-radius: 20px;
    font-size: 12px;
  }
}

md-toast.red {
  background-color: $alert_red;
}

md-toast.green {
  background-color: $alert_green;
}

md-toast.blue {
  background-color: $blue;
}

md-toast.yellow {
  background-color: $yellow;
}

md-checkbox.md-checked .md-icon {
    background-color: $blue;
}
md-checkbox .md-label {
    pointer-events: auto;
}
md-checkbox .md-label span {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/**
 *  SPINNER/PROGRESS BAR COMPONENTS
 */

md-progress-linear.md-bar.green {
  background-color: $green;
}
md-progress-linear.green .md-container {
  background-color: rgba($green, 0);
}
md-progress-linear.green .md-bar {
  background-color: $green;
}
md-progress-linear.md-bar{
  background-color: $blue;
}
md-progress-linear.md-hue-3 .md-container{
  background-color: rgba($blue, 0.05);
}
md-progress-linear.md-hue-3 .md-bar {
  background-color: $blue;
}

.kit_spinner {
  z-index: 200;
  position: absolute;
  left: 0;
  bottom: 6px;
}

.chart_spinner {
  position: absolute;
  top: 200px;
  left: 57%;
}

/**
 *  COOKIES POLICY COMPONENT
 */

 .cookies-policy_container{
    background-color: rgba(60, 60, 60, 1);
    color: $yellow;
    position: fixed;
    bottom: 0;
    padding:5px;
    left: 0;
    width: 100%;
    //height: 4vh;
    z-index: 999;
    text-align: center;
    line-height: 4vh;

    a{
      color: rgba($yellow, 0.6);
    }
 }


// No data backdrop
.md-noDataBackdrop {
  width: 100%;
  height: 96 + 285px;
  position: absolute;
  background-color: white;

  .block {
    margin: 0 auto;

  }
  .title, .message {
    text-align: center;
  }
  .title {
    color: $grey_darker;
    font-weight: 400;
    margin-bottom: 0;
  }
  .message {
    margin: 0;
    font-size: 20px;
    font-weight: 300;
  }
  a {
    color: $blue;
    text-decoration: none;
  }
}

/**
 *  MAP
 */

.map_state {
  position: fixed;
  z-index: 1;
}

// @media only screen and (max-device-width: 1024px) {
//   .map_state {
//   }
// }

section.map {
  background-color: white;
  text-align: center;
  width: 100%;
  height: 100%;
  color: black;
  z-index: 1;//$small_priority;
  position: fixed;
  top: 64px;

  @media all and (max-height: 1200px) and (min-height: 1000px) {
    height: 950px;
  }
  @media all and (max-height: 1000px) and (min-height: 800px) {
    height: 700px;
  }
  @media all and (max-height: 800px) and (min-height: 600px) {
    height: 550px;
  }
  @media all and (max-height: 600px) and (min-height: 400px) {
    height: 300px;
  }


  h1 {
    font-size: 3em;
  }
}

/*
  CORE CHIP COMPONENT
 */
.chips {
  display: inline-block;

  div.chips_row{
    height:40.5px;
  }

  .label, .tag{
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  }
}
.chip {
  // display: inline-block;
  padding: 0px 10px 0px 14px;
  margin: auto 5px;
  border-radius: 20px;
  height:30px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 29px;

  .chip_name {
    margin-left: 3px;
    margin-right: 7px;
  }
  .chip_icon {
    border: 0;
    background-color: inherit;
    md-icon {
      width: 8px;
      height: 8px;
    }
  }

  &.map_filter {
    background-color: white;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  }
  &.kit_tag {
    background-color: $grey;
    float: left;
    margin: 5px;
  }
}
/*
  MAP FILTERS
*/
.map_legend {

  position: absolute;
  top: 10px;
  margin-left: 10px;
  display: flex;

  .chips{
    align-self: flex-end;
    padding-bottom: 5px;
    padding-left: 5px;
    display: flex;
  }

  .map_legend__filtersContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 124px;
    height: 81px;

    border-radius:4px;
    overflow:hidden;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    svg, p {
      fill: $off_black;
    }


    .map_legend__filtersRow{
      cursor: pointer;

      &:not(:last-child){
        border-bottom: 1px solid gray;
      }

      display: flex;

      align-items: center;
      padding-left: 5px;
      background-color: #fff;

      .map_filter_button{
        box-shadow:none;
        p{
          margin: 0;
        }
      }
    }
  }
}
.map_filter_button {
  background-color: white;
  margin-right: 15px;
  width: 26px;
  height: 26px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);

  md-icon {
    width: 18px;
    height: 12px;
  }
}
.md-button.map_filter_button:hover, .md-button.map_filter_button:focus {
  background-color: white;
}



/*
=========================================
KIT TAGS MODULE
=========================================
*/

.kitTags__section{
  margin-top: 376px;

  .kitTags__listContainer{
    background-color: $grey_lighter;
    padding: 20px;
  }

  .kitTags__container{
    padding-bottom: 20px;

    .kitTags__textContainer{
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 20px;
      .kitTags__textElement{
        flex-basis:40%;
        flex-grow:0;
        min-width: 300px;
      }
    }
  }
}

/*
 CORE FORM COMPONENT
 */

.form_container {
  font-size: 16px;
  position: relative;
  border-radius: 0px 0px 3px 3px;
}
.form_contentContainer {
  width: 92%;
  margin: 0 auto;
}
.form_field {
  position: relative;
}

.form_errors {
  position: absolute;
  bottom: 15%;
  left: 5%;
  color: red;
  font-size: 0.7em;
  line-height: 1;
}

.form_closeIcon {
  float: right;
  width: 14px;
  height: 14px;
  margin: 12px;

  md-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.md-primary.form_button {
  color: $font_color_white;
  background-color: $terciary_color;
  height: 64px;
  bottom: 0px;
  left: 0px;
  border-radius: 0px 0px 2px 2px;
}


.form_title {
  color: $terciary_color;
  font-weight: normal;
}

.form_messageHeader {
  font-size: 1.5em;
  color: $secondary_color;
  font-weight: bold;
  line-height: 0;
  margin-top: 50px;
}
.form_messageSubheader {
  font-size: 0.875em;
  color: rgba(0,98,123,0.54);
  line-height: 0.5;
}

.form_messageDescription {
  font-size: 12px;
  margin-bottom: 0;
}

/*
  CORE LARGE DEVICES FORM
*/
@media (min-width: 501px) {
  .form_container {
    width: 340px;
    font-size: 16px;
  }
  .form_messageContainer {
    margin-bottom: 20px;
  }
  .md-primary.form_button {
    width: 340px;
    display: block;
    position: relative;
    margin: 0 auto;
    font-size: 1.5em;
  }
}

/*
  CORE MOBILE DEVICES FORM
*/

@media (max-width: 500px) {
  .form_container {
    width: 80%;
    font-size: 12px;
  }
  .md-primary.form_button {
    width: 100%;
    position: absolute;
    font-size: 1.2em;
  }
}

// override for Iphone 4
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .recovery_container {
    margin-top: 40px;
  }
  .form_field {
    padding: 5% 0;
  }
}

/*
  RECOVERY FORM
*/
.recovery_container {
  position: relative;
  margin: 0px auto;
  margin-bottom:100px;
//  height: 376px;
}

.formRecovery_field {
  padding: 10% 0;
}

@media (min-width: 501px) {
  .recovery_container {
    margin-top: 80px;
  }
  .formRecovery_errors {
    bottom: 15%;
    left: 5%;
  }
}

@media (max-width: 500px) {
  .recovery_container {
    margin-top: 20%;
  }
  .formRecovery_errors {
    bottom: 23%;
    left: 5%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

  @media (max-width: 500px) {
    .formRecovery_field {
      padding: 2% 0;
    }
    .formRecovery_errors {
      bottom: 29%;
    }
  }
}

/*
  RESET FORM
*/
.formReset_field {
  padding: 7% 0;
}

@media (min-width: 501px) {
  .formReset_errors {
    bottom: 5%;
    left: 5%;
  }
}

@media (max-width: 500px) {
  .formReset_errors {
    bottom: 18%;
    left: 1%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  @media (max-width: 500px) {
    .formReset_errors {
      top: 65%;
    }
    .formReset_field {
      padding: 3% 0;
    }
  }
}

/**
 * MOVE DOWN EFFECT ON SCROLL
 */

.move_down {
  -ms-transform: translateY(32px);
  -webkit-transform: translateY(32px);
  -moz-transform: translateY(32px);
  -o-transform: translateY(32px);
  transform: translateY(32px);
}

/*
  MARKER AND POPUP MARKER
*/

.markerSmartCitizenNormal {
  border: 1px solid $yellow;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.30);
}

.markerSmartCitizenOnline {
  border: 2px solid $yellow;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.30);
  background-color: rgba($yellow, 0.5);
}

.markerExperimentalNormal {
  border: 2px solid $background_blue_midtone;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.30);
  background-color: $background_blue_midtone;
}

/* @keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
} */

.markerSmartCitizenOffline {
  border: 2px solid rgba(0,0,0, 0.2);
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.30);
  background-color: rgba(0,0,0,0.2);
}

/* .marker_blink {
  -webkit-animation: pulse 2s linear infinite;
  -moz-animation: pulse 2s linear infinite;
  -ms-animation: pulse 2s linear infinite;
  animation: pulse 2s linear infinite;
} */

.popup {
  width: 101%;;
  height: 135px;
  color: $off_black;
  font-family: 'Roboto Condensed'
}

.popup_top {
  height: 72px;
  border-radius: 2px 2px 0 0;
  background-color: $background_blue_light;
  padding-top:5px;

  a {
    color: $off_black;
    &:hover {
      color: $grey_darker;
    }
  }

  .popup_name {
    padding: 0 0 1px 8px;
    font-size: 16px;
    font-weight: bold;
  }
  .popup_type {
    font-size: 14px;
    line-height: 1em;
    padding: 1px 0 3px 8px;
  }
  .popup_time {
    font-size: 11px;
    padding: 2px 0 0 8px;

    md-icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
}

.popup_bottom {
  height: 68px;

  .popup_location {
    padding: 5px 0 0 8px;
    font-size: 14px;

    md-icon {
      width: 8.4px;
      height: 12px;
      margin-right: 4px;
    }
  }
  .popup_labels {
    padding: 8px 0 0 8px;
    font-size: 12px;

    // span {
    //   // Make balloons on map smaller
    //   //border-radius: 20px;
    //   //margin-right: 10px;
    //   //padding: 3px 8px;
    // }

    tag, span {
      margin-bottom: 8px;
      float: left;
    }
  }
}
.popup_icon svg:nth-child(2) {
  width: 0;
  height: 0;
}

/* Kit type colors kitUtils.service.js :: classify()*/

.sck {
  background-color: $yellow;
}
.unknown {
  background-color: grey;
}
/**
 * OVERRIDE LEAFLET
 */

.leaflet-left {
  // This is a hack. The right way is to change the leaflet API. See the docs:
  // http://leafletjs.com/reference-0.7.7.html#control-position
  left: unset !important;
  right: 11px;
  top: 15px !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 4px;
  width: auto !important;
  overflow: hidden;
}

.leaflet-popup-content {
  margin: 0;
  min-width: 245px;
  position: relative;
  top: -1px;
  left: -1px;

  p {
    margin: 0;
  }
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-overlay-pane .leaflet-zoom-animated path {
  stroke: grey;
}
// override z-index of map controls
.leaflet-top.leaflet-left {
  z-index: 1;
}
.leaflet-bottom.leaflet-right {
  z-index: 1;
}

.marker-cluster {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.20);
  div {
    //margin-top: -2px;
    //margin-left: -2px;
    //width: 40px;
    //height: 40px;
    font-family: 'Roboto Condensed';
    font-size: 14px;
    //line-height: 40px;
    //font-weight: normal;
  }
}

.marker-cluster-small, .marker-cluster-medium, .marker-cluster-large {
    border-radius: 50%;
    border: 2px solid rgba($yellow, 0.8);
    background-color: rgba($yellow, 0.3);
  div {
    background-color: rgba(0,255,168,0);
  }
}

/*
  PICKER COMPONENT
*/

.picker {
  color: $off_black;
}

.picker__weekday {
  color: $grey_darker;
}

.picker__nav--prev::before {
  border-right: 0.75em solid $grey_darker;
}

.picker__nav--next::before {
  border-left: 0.75em solid $grey_darker;
}

.picker__button--today::before {
    border-top: 0.66em solid $blue;
}

.picker__holder.picker_container {
  background: none;
  transition: none;
}

.picker__button--clear::before {
    border-top: 3px solid $red;
}
.picker__button--close {
  color: $off_black;
}
.picker__button--today, .picker__button--clear {
  color: $off_black;
}
.picker__day--highlighted, .picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected{
  background: $yellow;
  background-color: $yellow;
  border-color: $yellow;
  color: $off_black;
}
.picker__day--infocus:hover, .picker__nav--prev:hover, .picker__nav--next:hover, .picker__button--today:hover, .picker__button--clear:hover, .picker__button--close:hover {
  background: $yellow_lighter;
  border-color: $yellow_lighter;
}

.date_picker {
  height: 24px;
  border-radius: 2px;
  border: 1px solid $grey_darker;
  color: $grey_darker;
  background-color: transparent;
}

.select_image {
  margin-right: 10px;
}

/**
 * DROPDOWN MENU
 * Override style for dropdown menu from angular-dropdowns dependency
 */
 .wrap-dd-menu .dropdown li a {
   color: $secondary_color;
 }

.kit_menu {

  .wrap-dd-menu {
    width: 30px;
    display: inline-table;
    padding: 0 0;
  }
  .wrap-dd-menu .dropdown {
    top: 70%;
    line-height: 1em;
    left: -25px;
    right: -25px;
    li a {
      text-align: center;
      border-bottom: 0px;
    }
  }
}
.kitList_parent {

  .wrap-dd-menu {
    width: 50px;
    display: inline-block;
  }
  .wrap-dd-menu .dropdown {
    top: 70%;
    line-height: 1em;
    left: -25px;
    right: -25px;
    border-radius: 0px;
    li a {
      text-align: center;
      border-bottom: 0px;
    }
  }
}

.kit_owner_dropdown_button {
  display: block;
  margin: 0 auto;
}

/**
 *  KIT LIST
 *
 */
.kitList {
  margin: 0px;
  color: $grey;
  border-top: 1px solid #EAECF1;
  border-right: 1px solid #EAECF1;
  border-left: 1px solid #EAECF1;
  background-color: white;
  border-radius: 0 !important;
  text-transform: none;
  padding:15px;

  h1,h2,h3,h4{
    line-height: inherit;
  }
  p{
    line-height: 0.4em;
  }

  .wrap-dd-menu {
    width: 50px;
    display: inline-block;
  }
  .wrap-dd-menu .dropdown {
    top: 70%;
    line-height: 1em;
    left: -25px;
    right: -25px;
    border-radius: 0px;
    li a {
      text-align: center;
      border-bottom: 0px;
    }
  }

  .chips {
    position: absolute;
    right: 130px;
  }

}

.kitList_avatar {
  width: 44px;
  height: 44px;
  border-radius: 22px;
}
.kitList_name {
  margin-bottom: 5px;
  font-size: 18px;
  color: $blue;
}
.kitList_right {
  position: absolute;
  right: 0;

  .chip .chip_name {
    text-transform: uppercase;
  }
}
.kitList_state {
  color:white;
  text-transform: uppercase;
}
.kitList_state_has_published {
  color: $green;
}
.kitList_state_never_published {
  color: $yellow;
}
.kitList_state_not_configured {
  color: #F43D4D;
}
.kitList_dropdownButton {
  display: block;
  margin: 0 auto;

  img {
    position: relative;
  }
}

.kitList_noKits {
  line-height: 5;
}

.dropdown-item-button {
    border-radius: 5px;
    text-align: center;
    width: 100%;
}
.dropdown-item-button:hover{
  background-color: $yellow !important
}

md-card.kit-list-item {
  border-radius: 10px;
}

/**
 *  USER PROFILE STATE
 *  most styles are re-used on my profile state
 */

/* profile header */
.profile_header {
  background-color: $background_blue_lighter_menu;
  color: $font_color_blue_grey;
  height: 192px;
  margin-top: 64px;
}
.profile_header_avatar {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 29px;
  margin-left: 27px;
}
.profile_header_content {
  >div {
    margin: 5px 0;
  }
  svg {
    fill: $grey_lighter;
  }
}
.profile_header_content_avatar {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  position: relative;
  top: -1px;
}

.profile_content {
  min-height: 700px;
  position: relative;
}
/* profile sidebar */
.profile_sidebar {
  //min-height: 400px;
  // min-height: inherit;
  // width: 256px;
  background-color: #F5F5F5;
  min-width:200px;
}
.profile_sidebar_title {
  color: $off_black;
  font-weight: bold;
}
.profile_sidebar_description {
  font-size: 14px;
}

.profile_sidebar_button {
  height: 64px;
  color: $terciary_color;
  svg { fill: $blue; }
}

.profile_sidebar_avatar {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  position: relative;
  top: -2px;
}

/* profile main content */
.profile_content_main_top {
  margin: 0 0 42px 20px;
}

/**
 *  MY PROFILE STATE
 */

//override angular-material
.myProfile_state {
  md-ink-bar{
    // Tabs underline color cyan
    background-color: $terciary_color;
  }
  md-tab md-tab-label {
    overflow: visible;
  }
  md-tabs md-tab {
    color: $font_color_blue_grey;
  }
  md-tabs md-tab.active {
    color: white;
  }
  md-tabs md-tabs-ink-bar {
    color: $blue;
    background-color: $blue;
  }
}

md-tabs-canvas {
  background-color: #2E2E2E;
}

.myProfile_header {
  height: 188px;
  position: relative;
}
.myProfile_header_container {
  padding-top: 51px;
  margin-left: 46px;
}
.myProfile_header_avatar {
  margin-left: 0;
}
.myProfile_tabs_parent {
  md-tabs-wrapper{
    background-color: $background_blue_dark_menu;
  }
}
// .myProfile_tabs {
// }
.myProfile_tab_icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  position: relative;
  top: -1px;
  svg {
    fill: white;
  }
}
.myProfile_sidebar_button {
  padding-left: 28px;
}
.myProfile_content_form {
  margin-left: 113px;
  max-width: 500px;
}
.myProfile_content_form_input {
  margin: 16px 0;
  position: relative;
}
.countryInput_container {
  md-autocomplete {
    background-color: transparent;
  }
  md-autocomplete-wrap {
    box-shadow: none !important;
  }
  input {
    font-size: 100%;
  }
  label {
    position: relative;
    bottom: 55px;
  }
}
.myProfile_form_avatar {
  margin-bottom: 30px;
}
.myProfile_form_avatarImage {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-right: 7%;
}
.myProfile_apiKey_text {
  color: $font_color_blue_grey;
  margin-right: 5px;
  width: 120px;
}
.myProfile_apiKey_number {
  color: #9D9D9E;
  font-weight: bold;
  background-color: #F2F2F2;
  padding: 5px 10px;
}
.myProfile_apiKey_refreshButton {
  background-color: $font_color_blue_grey;
  height: 29px;

  md-icon {
    height: 15px;
    width: 15px;
    display: block;
    margin: 3px auto;
  }
}
.md-button.myProfile_apiKey_refreshButton {
  border-radius: 0 4px 4px 0;
}
.md-button.myProfile_apiKey_refreshButton:active, .md-button.myProfile_apiKey_refreshButton:hover, .md-button.myProfile_apiKey_refreshButton:focus {
  background-color: $font_color_blue_grey;
}
.myProfile_form_removeButton {
  display: block;
  color: $alert_red;
  margin-bottom: 5px;
}
.myProfile_updateForm_error {
  position: absolute;
  top: 50px;
  left: 2px;
  color: red;
  font-size: 12px;
  line-height: 1;
}
.myProfile_apiKey_block {
  margin-top: 63px;
  margin-bottom: 65px;
}

.myProfile_apiKey {
  margin-top: 10px;
  margin-bottom: 10px;
  svg {
    fill: $grey_darker;
  }
}

.md-button.myProfile_addKitButton:focus {
  background-color: $blue;
}
.md-button.myProfile_addKitButton:hover {
  background-color: $blue;
}
.myProfile_hiddenhref{
  position:absolute;
  height: 100%;
  width:100%;
  top:0;
  left:0;
}

/**
 * TOOL LIST
 *
 */

 a.kitList {
   h4 {
     color: $off_black;
   }
   p {
     color: $grey_darker;
   }
 }

.clearfix {
  clear: both;
}

/*
  FOOTER COMPONENT
*/

footer {
//  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: $background_blue_dark_background;
}

.footer_icon {
  display: block;
  width: 32px;
  height: 32px;
}
.flag {
  padding-right: 15px;
}
.border-white.footer-block{
  border: 2px white solid;
}
.uptimerobot-logo {
  width: 150px;
  justify-self: right;
  vertical-align: center;
  padding-top: 10px;
}
.uptimerobot-sponsor {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}
.uptimerobot-sponsor-text p {
  font-size: 15px !important;
  margin: 0px;
}
@media only screen and (max-width: 950px) {
  .color-white{
    text-align: center;
  }
  .flag {
    padding-right: 0px;
  }
  .footer-block {
    margin-bottom: 20px !important;
  }
  .sponsor {
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 599px) {
  .layout-align-xs-start-stretch, .layout-align-xs-center-stretch, .layout-align-xs-end-stretch, .layout-align-xs-space-between-stretch, .layout-align-xs-space-around-stretch{
    align-items: center;
  }
}


/*
  RECOVERY PASSWORD STATE
*/
.recovery_header {
  width: 100%;
  height: 128px;
  background-color: $background_blue_dark_background;
}

.recovery_headerIcon {
  display: block;
  width: 50px;
  height: 50px;
}

/*
  TIMELINE COMPONENT

  used on add and setup kit
*/

.timeline {
  // margin-top: 64px;
  // background-color: $background_blue_dark_menu;
  height: $timeline-height;
  background-color: $background_blue_lighter_menu;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 64px;
  box-shadow: 0 4px 5px -2px $background_blue_lighter_menu;
  padding: 30px;
}

@media (max-width: 960px) {
  .timeline-btn-extra {
    display: None;
  }
}

@media (min-width: 0px) and (max-width: 959px) and (orientation: portrait) {
  .timeline {
    top: 56px;
  }
  .profile_header {
    margin-top: 53px;
  }
}

@media (min-width: 0px) and (max-width: 959px) and (orientation: landscape) {
  .timeline {
    top: 53px;
  }
  .profile_header {
    margin-top: 53px;
  }
}

.timeline_container {
  width: 100%;
}
.timeline_line {
  width: 500px;
  border: 1px solid $yellow;
  position: relative;
  top: 14px;
}
.timeline_line_small {
  width: 100px !important;
  margin: 0px 20px;
  top:0px !important;
}

.timeline-title {
  text-transform: uppercase;
  color: $yellow;
  font-size:24px;
  font-weight: 600; // TODO: Cosmetic

  &.vertical {
    margin-top: 10px;
  }
}

.timeline_stepName {
  text-transform: uppercase;
  color: $yellow;
  font-size:18px;

  &.vertical {
    margin-top: 10px;
  }
}

.timeline_content {
  min-height: 400px;
  width: 100%;
  margin-top: $content-margin-top;
}

// .timeline_button {
//   width: 99%;
//   height: 90px;
//   font-size: 26px;

//   &.timeline_buttonOpen.md-primary {
//     color: $terciary_color;
//     text-align: center;

//     &.inverted{
//       color: white;
//       background-color: $terciary_color;

//       small {
//         color: white;
//       }
//     }

//     .main {
//       margin: 10px;
//       font-size: 32px;
//     }

//     .sub {
//       margin: 10px;
//       font-size: 16px;
//     }

//     small {
//       color: rgba(0,0,0,0.54);
//       text-transform: initial;
//       display: block;
//       font-size: 16px;
//     }

//   }
// }

.md-button.timeline_buttonBack{
  margin-left: auto;
  margin-right: 20px;
}

section.relaxed-layout {
  padding: 10px 25px;
}



/*
  ADD KIT STATE
*/

.kit_dataChange {
  .form_block {
    padding: 40px 30px;
    background-color: #FFF;

    &.isEven {
      background-color: #F9F9FB;
    }
    // &.form_blockNormal {
    //  // min-height: 250px;
    // }
    // &.form_blockMap {
    //   //height: 290px;
    // }
  }
  .form_blockInput {
    height: inherit;
   // margin-left: 150px;
  }
  .form_blockInput_container {
    height: inherit;
  }
  .form_blockInput_button {
    height:inherit;
  }
  .form_blockInput_map {
    height: 250px;
    min-width: 250px;
  }
  .form_blockInput_select label{
    color: $terciary_color;
    margin-right: 10px;
  }
  .form_blockInput_chips {
    margin-top: 35px;
  }
  .form_blockContent {
    padding-top: 5px;
    float: left;
    img, div {
      float: left;
    }
    img {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      margin: 5px 20px;
    }
  }
  .form_blockContent_image {
    position: relative;
    top: 3px;
  }
  .form_blockContent_text {
    margin-left: 10px;
    width: 240px;
    &.long{
      width: auto !important;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      color: #A5A5A5;
      line-height: 1.5;
    }
  }
  .form_errors {
    position: absolute;
    bottom: 15%;
    left: 0;
    color: red;
    font-size: 0.7em;
    line-height: 1;
  }
}

.emoji{
  font-weight:400;
}

/*
  STATIC PAGES
*/
.static_page{
  .timeline{
    align-items: flex-start stretch;

    .content{
      padding:0 0px;
    }
  }

  .content{
    max-width: 50em;
    margin: 0 auto;
    text-align: justify;
    padding:40px 10px;
  }

  .full-width-img {
    width: 100%;
    height: auto;
    max-width: 2000px;
    position: relative;
    margin: 0 0 -5px 0;
  }

  .embed-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
  }

  .center {
      text-align: center;
      margin-top: 30px;
  }

  .row{
    min-height: 200px;

    &:nth-child(odd){
      background-color: #F9F9FB;
    }
  }
  h1{
    color:white;
  }
  h4{
    font-weight: bold;
    margin-bottom: 0.2em;
  }

  .md-button.md-primary.md-raised, .md-button.md-primary.md-fab{
    padding: 6px 20px;
    height: 44px;
    border-radius: 22px;
    font-size: 18px;
    background-color: $blue;
    color:white;

    &:hover, &:focus{
      background-color: #3C98AF;
    }

    &:not([disabled]){
      box-shadow: none;
    }
  }

  .md-button.md-primary{
    color: $blue;
  }
}

.not-found-404 {
  height: 36vh;
}

@media (min-width: 960px) {
  .not-found-404 {
    height: 67.3vh;
  }
}

// About

.sensor-image-margin {
  margin-top: 40px;
}

h3.no-margin {
  margin: 0;
}

p.no-margin {
  margin: 0;
}

.team-cells-margin {
  margin-bottom: 25px;
}

.subtitle-separation {
  margin-top: 60px;
}

// Feedback

.doorbell-button {
 background-color: $yellow !important;
}

// Styleguide

.styleguide {
  .info-text {
    color: rgba(0, 0, 0, 0.3);
  }

  .info-text-dark {
    color: rgba(255, 255, 255, 0.3) !important;
  }

  .dark-theme-divider {
    border-top-color: rgba(255,255,255,0.12);
  }

  small {
    margin-top: 20px;
  }

  .dark-text-section {
    background-color: $background_blue_dark_background;

    h1 {
      font-size: 1.8em;
      color: #fff;
    }

    h2 {
      color: #fff;
    }

    h4 {
      color: $font_color_blue_grey;
    }

    h6 {
      color: #87CCDD;
    }

    p{
      color: #C8E6ED;
      font-weight: 300;
    }

    small {
      color: $secondary_color_light;
    }
  }

  .section-padding {
    padding: 60px;
  }

  .colors-section {
    p {
      padding-left: 20px;
    }

    .secondary-color {
      background-color: $secondary_color;
      p {
        color: #fff;
      }
    }

    .terciary_color {
      background-color: $terciary_color;
      p {
        color: #fff;
      }
    }

    .secondary_color_light {
      background-color: #8DB2BA;
      p {
        color: #fff;
      }
    }

    .secondary-color-pastel {
      background-color: #C8E6ED;
      p {
        color: #8DB2BA;
      }
    }

    .white {
      background-color: #fff;
      border:1px solid #C8E6ED;
      p {
        color: #8DB2BA;
      }
    }

    .sensors > div {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      float: left;
      margin: 10px;
    }

    .sensors div:nth-child(1) {
     background: #ffc107;
    }

    .sensors div:nth-child(2) {
     background: #4fc3f7;
    }

    .sensors div:nth-child(3) {
     background: #ffee58;
    }

    .sensors div:nth-child(4) {
     background: #f06292;
    }

    .sensors div:nth-child(5) {
     background: #4caf50;
    }

    .sensors div:nth-child(6) {
     background: #8bc34a;
    }

    .sensors div:nth-child(7) {
     background: #9575cd;
    }

    .sensors div:nth-child(8) {
     background: #fff9c4;
    }

    .sensors div:nth-child(9) {
     background: #ffee58;
    }

    .sensors div:nth-child(10) {
     background: #ff5722;
    }
  }

}


/* Custom Animations */

.loading-dots span:after {
  content: '.';
  animation: dots 2s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 $yellow,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 $yellow,
      .5em 0 0 $blue;}}


/* Do not remove this comments bellow. It's the markers used by gulp-inject to inject
   all your sass files automatically */
// injector
// endinjector
